@import "../base.scss";

//for all font
* {
  font-family: $montserrat;
}

//NAVBAR
.logo-pasar {
  padding: 0;

  // align-self: center !important;
}

// .logo-pasar-mobile{
//   width: 100%;
//   background-color: #ff7;
//   display: flex;
//   justify-content: center;
// }

.cart-style {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a,
  a:hover {
    color: black;
    text-decoration: none;
  }
}

.br-mobile {
  display: none;
}

.container-profile {
  position: relative !important;
  padding: 0 8px;
  margin-left: 6px;

  .profiles {
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;

    &:hover {
      background: #f8f8f8;
    }
  }
}

.user-icon {
  position: relative !important;
  margin-top: 5px;
  margin-right: 5px;
}

.link-login {
  background-color: #ef3f3a;
  border: 0;
  border-radius: 60px;
  font-weight: bold;
  color: #fff !important;
  margin: auto;
  margin-left: 5px;
  padding: 9.5px 22px;
}

.count-wrapper {
  @include centerFlex;
  background-color: red;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
  right: -10px;
  bottom: 15px;
  background-color: "red";
  font-size: 10px;
}

.cart-icon {
  position: relative;

  &.cart {
    .count-wrapper {
      bottom: 10px;
    }
  }
}

.burger-icon {
  margin-bottom: 0;
}

.count-cart {
  font-weight: bold;
  color: white;
}

.back-button,
.inactive {
  display: none;
}

.menu-area,
.hide-menu-area {
  @include widthHeight(100%, 70px);
  padding: 0 3.5%;
  z-index: 99;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  background-color: #fff;
  top: 0;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.15);
  overflow: visible;

  .col {
    padding: 0;
  }

  .btn-search-nav {
    display: none;
  }
}

.navbar-style {
  @include centerFlexAlign;
}

//FOOTER
.footer {
  margin-top: 60px;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.05);
  // background: #f8f8f8;

  // padding: 42px 0 30px 0;
  .row-logo {
    justify-content: center;

    &.bri {
      h2 {
        font-size: 1em;
        font-weight: 800;
      }

      img {
        margin: 1rem 0;
      }
    }

    .logo-pasar {
      text-align: center;
    }
  }

  .nav-foot {
    h2 {
      font-size: 1.2em;
      font-weight: 800;
      width: auto;
    }

    ul {
      list-style-type: none;
      padding: 0;

      a {
        text-decoration: none;
        color: #757575;
        font-size: 0.9em;
      }
    }
  }

  .sosmed {
    .ic {
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
    }

    .ig {
      color: #ff543e;
    }
  }

  h3 {
    font-size: 1.4rem !important;
    margin-top: 0;
    margin-bottom: 1.5rem;
    // font-weight: 600;
  }

  .bri-logo {
    height: 30px;
    object-fit: contain;
    margin-bottom: 1.6rem;
  }

  h5 {
    font-family: "Lato";
    font-weight: normal;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  p {
    font-family: "Lato";
    font-weight: 300;
    margin-bottom: 0;
    font-size: 15px;
  }
}

.ico-link {
  margin: 0 0.7em;
}

#btn_search_mobile_di_navbar {
  display: none;
}

.cr-footer {
  .row {
    margin: 20px 0 0 0 !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  #btn_search_mobile_di_navbar {
    display: block;
  }

  .footer {
    box-shadow: none;
    padding: 0;

    h3 {
      font-size: 18px !important;
      margin-top: 0;
      margin-bottom: 17px;
      font-weight: bold;
    }

    .bri-logo {
      height: 49px;
      width: 80px;
      margin-bottom: 5px;
    }

    h5 {
      font-family: "Lato";
      font-weight: normal;
      font-size: 14px !important;
      margin-bottom: 25px;
    }

    p {
      // text-align: center;
      max-width: 280px;
      font-size: 13px;
    }
  }

  .row-logo {
    justify-content: left !important;

    .logo-pasar {
      text-align: left !important;
      padding-left: 5px !important;
      padding-bottom: 10px;

      img {
        height: 70px !important;
      }
    }
  }

  .nav-foot {
    h2 {
      display: none;

      &.follow-us {
        display: block;
        font-size: 0.9rem;
      }
      &.our-mitra {
        display: block;
        font-size: 0.9rem;
        margin-bottom: 0;
      }
    }

    ul {
      margin-bottom: 0 !important;
    }

    li {
      margin-bottom: 10px;
      font-weight: 700 !important;
    }

    .item-nav-foot {
      width: 50%;
    }
  }

  .wrapper-copy {
    width: 100vw !important;
    margin: 20px 0 0 0 !important;
    text-align: center;
    background: #f8f8f8;
    align-items: center;
    height: 3rem;
  }

  .copyright {
    color: #6b7280;
    font-size: 13px;
    // margin-left: 10px;
    // margin-bottom: 10px;
  }

  .leaflet-control-container {
    .leaflet-geosearch-button,
    form {
      max-width: 300px !important;
    }
  }
}

.up-content {
  @include centerFlexAlign;
  flex-direction: column;
}

//BUTTON
.bu {
  background-color: $whiteColor;
}

.lighter {
  background-color: $whiteColor;
  border: none;

  &:hover {
    background-color: $whiteColor;
  }
}

.center-logo {
  display: block;
  margin: 0 auto;
  transition: 0.3s all;
  margin-left: 30%;
}

.left-logo {
  transition: 0.3s all;
}

//INPUT
.input-group {
  background-color: $whiteColor;
  height: 64px;
  width: 632px;

  &-text {
    background-color: white !important;
    border: none !important;
  }
}

.form-control {
  border: none;
}

//SEARCH BAR

.container-search {
  @include centerFlexAlign;
  flex-direction: row;
  height: 40px;
  width: 700px;
  border-radius: 20px;
  position: sticky;
  background-color: $whiteColor;
  z-index: 2;

  &:hover {
    border: none;
  }

  overflow: visible;

  .search-produk-lapak-pasar {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #adadad;
    background-color: #fafafa;
    border-radius: 18px;
    justify-content: space-between;
  }
}

.search-location {
  font-size: 14px;
  width: 80%;
  display: flex;
  padding: 8px 10px;
  margin: 2% 10%;
  cursor: pointer;
  margin-bottom: 0;
  border-radius: 10px;
  align-items: center;
  border: 1px solid #ebebeb;
  background-color: #f4f4f4;
  justify-content: space-between;

  &:hover {
    background-color: #fafafa;
    border: 1px solid #adadad;
  }
}

.cari-lokasi {
  width: 100%;
  text-align: end;
  p {
    margin: 0;
    padding: 0;
    width: 100%;
    color: red;
    font-weight: bold !important;
  }
}

.pin-map {
  @include centerFlex;
  padding-right: 10px;
  width: fit-content;
}

.icon-map {
  width: 22.93px;
}

.hide-container-search {
  display: none;
}

.search-input {
  display: flex;
  flex: 6;
  align-items: baseline;
  position: relative;

  .input-form {
    width: 100%;
    border: none;
    background-color: inherit;
    outline: none;
    padding: 0 1%;
    border-right: 1px solid #ebebeb;

    &:hover {
      background-color: inherit;
      border: none;
      box-shadow: none;
    }
  }
}

.container-suggestion {
  left: -14px;
  margin-top: 10px;
  background-color: white;
  width: 650px;
  z-index: -1;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  :hover {
    background-color: rgb(245, 244, 244);
    border-radius: 4px;
  }
}

.suggestion-search {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 12px 20px;

  p {
    margin-bottom: 0;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .faicon {
    width: 30px;
    margin-right: 5px;
    color: red;
  }
}

.rbt-input-main {
  &.form-control {
    &.rbt-input {
      font-size: 14px;
      width: 100% !important;
      border: none !important;
      background-color: inherit !important;
      outline: none !important;
      padding: 0 3% !important;
      box-shadow: none !important;

      &:hover {
        background-color: inherit !important;
        border: none !important;
        box-shadow: none !important;
      }

      &:focus {
        outline: none !important;
        border: none !important;
      }
    }
  }
}

.button-search {
  width: 60px;
  display: flex;
  justify-content: center;
  // border-left: 1px solid #ebebeb;
}

.btn {
  &-dropdown,
  &-search {
    border: none;
    outline: none;
    background-color: inherit;
    width: 100%;
    // max-width: 180px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;

    &:active,
    &:active:focus {
      border: none;
      outline: none;
    }
  }
}

hr.solid {
  height: 40%;
  border-left: 3px solid #e5e5e5;
  margin: 0;
}

//DROPDOWN
.dropdown-container {
  align-items: center;
  background-color: inherit;
  position: relative;
  width: 85%;

  &-button {
    position: absolute;
    top: 8px;
  }
  .dropdown-toggle {
    align-items: center;
    font-weight: bold;
  }
}

.chevron-wrapper {
  background: #7a8d3f;
  margin: 0 auto;
  padding: 10px;
  padding-left: 5px;
  height: 100%;
  cursor: pointer;
  .down-ico {
    margin-top: 15px;
    color: #fff;
  }
}

.search-city {
  &.icon {
    color: #ed4140 !important;
  }
}

.dropdown-toggle::after {
  display: none !important;
}

#button-addon2 {
  width: fit-content;
}

.btn-dropdown:focus {
  outline: none;
}

.dropdown {
  display: block;
  top: 15px;
  left: -38px;
  width: 200px;
  padding: 5px;
  background-color: white;
  z-index: 5;
  height: max-content;
  overflow-y: hidden;
}

.menu-item {
  min-width: 340px;
  height: 50px;

  background-color: #ffff;
  display: flex;
  align-items: center;
  border-radius: 1px solid black;
  transition: 0.5;
  padding: 6px;
  overflow: hidden;

  &:hover {
    color: black;

    text-decoration: none;
    background-color: rgb(245, 244, 244);
  }

  text-decoration: none;
  color: black;
  font-weight: normal;
  font-size: 16px;
}

.dropdown-indonesia {
  padding: 0.5rem 5px;
  cursor: pointer;
  &:hover {
    background-color: rgb(245, 244, 244);
  }
}

.icon-button {
  margin-right: 10px;
}

.label-location {
  color: #7a8d3f;
}

.footer-container-cart,
.footer-detail-produk,
.footer-container {
  padding: 2%;
  text-align: center;
}

.cara-belanja {
  cursor: pointer;
  padding: 5px;
  margin: 15px auto;
  border-radius: 8px;
  width: fit-content;
  border: 1px solid #7a8d3f;
  label {
    cursor: pointer;
    margin-bottom: 0;
    color: #7a8d3f;
  }
  width: 300px;
}
.logo-footer {
  width: 40%;
  margin: auto;
  padding-bottom: 25px;
  border-bottom: 1px solid #c4c4c4;
}
.logo-pasar-image {
  width: 40%;
}
.title-footer {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
}
.title-mitra-kami {
  font-weight: bolder;
  margin-bottom: 0;
}
.title-copy-right {
  color: #757575;
}

//RESPONSIVE
@include media("<=phone") {
  .br-mobile {
    display: block;
  }
  .chevron-wrapper {
    display: none;
  }
  .link-login {
    background: #fff;
    padding: 10px;
    color: #000 !important;
    .label {
      display: none;
    }
  }

  .suggestion-search {
    p {
      padding-left: 0;
    }
  }

  .chevron-wrapper {
    display: none;
  }

  .link-login {
    background: #fff;
    padding: 10px;
    color: #000 !important;

    .label {
      display: none;
    }
  }

  .apps {
    display: none !important;
  }

  .dropdown {
    top: 1px !important;
    box-shadow: 0px 8px 16px 0px rgba(177, 177, 177, 0.2);
  }

  .menu-item {
    width: 100% !important;
    min-width: 10rem;
    max-width: 18rem;
    padding-left: 14px;
  }

  .dropdown-indonesia {
    padding-left: 14px;
  }

  .container-search {
    margin-bottom: 60px;
  }

  .logo-city {
    display: none;
  }

  .container-profile {
    a {
      padding: 0 !important;
    }
  }

  .ico-link {
    margin: 0 6px;
    margin-left: 11px !important;
  }

  .back-button,
  .shopping-cart {
    display: flex !important;
  }

  .menu-area {
    @include widthHeight(100%, 115px);
    padding: 0 20px;

    .logo-pasar {
      // align-self: initial;
      img {
        display: block;
        width: 119px !important;
        height: 40px;
      }

      padding: 0;

      &.active {
        display: none;
      }
    }

    .btn-search-nav.active {
      display: inline;
      border: none;
      outline: none;
      background-color: inherit;

      &:active,
      &:active:focus {
        border: none;
        outline: none;
      }
    }

    .content-nav {
      justify-content: end;
      align-items: center;
    }

    .user-icon {
      align-self: flex-end;

      &.active {
        display: none;
      }
    }

    .cart-style {
      padding: 0 !important;
      align-items: center;
      display: flex;
    }
  }

  .hide-menu-area {
    @include widthHeight(100%, 70px);
    padding: 0 20px;

    .logo-pasar {
      // align-self: initial;
      img {
        display: block;
        width: 119px !important;
        height: 40px;
      }

      padding: 0;

      &.active {
        display: none;
      }
    }

    .btn-search-nav.active {
      display: inline;
      border: none;
      outline: none;
      background-color: inherit;

      &:active,
      &:active:focus {
        border: none;
        outline: none;
      }
    }

    .content-nav {
      justify-content: end;
      align-items: center;
    }

    .user-icon {
      align-self: flex-end;

      &.active {
        display: none;
      }
    }

    .cart-style {
      padding: 0 !important;
      align-items: center;
      display: flex;
    }
  }

  // .dropdown-container,
  // .pin-map,
  hr.solid {
    display: none;
  }

  .container-footer {
    p {
      margin: 0;
      max-width: 100%;
    }
  }

  .footer-container-cart {
    margin-bottom: 240px;
  }
  .footer-detail-produk {
    margin-bottom: 100px;
  }
  .footer {
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.05);
  }
  .logo-footer {
    width: 70%;
    padding-bottom: 0px;
  }
  .logo-pasar-image {
    width: 70%;
  }
  .cara-belanja {
    width: 200px;
    label {
      font-size: 14px;
    }
  }
}

.padd {
  padding: 1rem;
  padding-bottom: 0 !important;
}

.mitra {
  color: #757575;
  font-size: 1rem;
  margin-top: 1rem;

  .mitra-bri {
    font-weight: bold;
    width: 130px;
    // margin-right: 2.25rem;
  }
}

@include media(">phone", "<=tablet") {
  .container-search {
    width: inherit;
  }
}

@media only screen and (min-width: 768px) {
  .padd {
    padding: 5%;
  }
}

@media only screen and (max-width: 1024px) {
  .container-search {
    width: 500px;

    .search-location {
      width: 150px;

      .dropdown-container {
        width: 130px !important;

        &-button {
          width: 110px;
        }
      }
    }

    .search-input {
      .input-form {
        left: 40%;
      }
    }
  }

  .dropdown {
    width: 150px;
  }

  .container-suggestion {
    width: 500px;
    left: -10px;
  }
}

@media only screen and (max-width: 768px) {
  .container-search {
    width: 300px;
  }
  .search-location {
    width: auto;
    margin: 5% 5% !important;
    padding: 8px 10px;
    font-size: 12px;
    text-overflow: ellipsis;
  }
  .dropdown-container {
    width: 68%;
    text-overflow: ellipsis;

    &-button {
      width: 90px;
    }
  }

  .pin-map {
    padding-right: 7px;
  }

  .icon-map {
    height: 20px;
    width: 20px;
  }

  .dropdown {
    width: 132px;
    text-overflow: ellipsis;
  }

  .container-suggestion {
    width: 100%;
    left: -5px;
  }

  .button-search {
    width: 50px;
  }
}

@media only screen and (max-width: 425px) {
  .mitra-bri {
    // margin-right: 0.5rem !important;
  }

  .logo-pasar-footer {
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    margin: 1rem 0px;
    padding: 10px 0px;
  }

  .dropdown {
    width: 270px;
    left: 89px;
  }

  .container-search {
    width: 100%;
    // margin: 10px 0;

    .search-location {
      border-radius: 15px 0 0 15px;

      .dropdown-container {
        width: 135px !important;

        &-button {
          width: 105px;
        }
      }
    }

    .search-produk-lapak-pasar {
      border-radius: 10px;
      height: 40px;
    }
  }

  .dropdown {
    width: 144.5px;
    left: -37px;
    top: 15px !important;
  }

  .container-suggestion {
    width: 90vw;
    // left: -24px;
  }
}

@media only screen and (max-width: 375px) {
  .mitra-bri {
    // margin-right: 3rem !important;
  }

  .container-suggestion {
    width: 90vw;
  }
}

@media only screen and (max-width: 320px) {
  .mitra-bri {
    margin-right: 2.5rem !important;
  }
  .sosmed {
    .ic {
      margin: 0px 5px !important;
    }
  }

  .search-location {
    font-size: 11px;
    .dropdown-container {
      &-button {
        width: 85px !important;
      }
    }
    .pin-map {
      padding-right: 5px;
      .icon-map {
        width: 17px;
      }
    }
  }

  .dropdown {
    width: 116px;
    left: -33px;
  }

  .container-suggestion {
    width: 90vw;

    left: -8px;
  }
}
