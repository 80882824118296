@import "../../scss/base.scss";
.custom-toast {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto !important;
  z-index: 9000;

  max-width: 600px;
  padding: 6px;
  background: #4cf0e2;
  color: #333333;
  text-align: center;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  visibility: hidden;
  opacity: 1;
  transition: opacity 0.2s, top 0.2s, visibility 0.2s;
}

.custom-toast--visible {
  z-index: 9999999999999999999999999999999;
  top: 20%;
  // right: 30px !important;
  // margin: 0px 15px !important;
  opacity: 1;
  visibility: visible !important;
  // display: block !important;
}

.custom-toast--success {
  background: #00c02b;
  border-color: #009d23;
  color: #ffffff;
}

.custom-toast--error {
  background: $redColor;
  border-color: $redColor;
  color: #ffffff;
}

@include media("<=phone") {
  .custom-toast--visible {
    margin: 0px 15px !important;
    font-size: 14px !important;
  }
}
