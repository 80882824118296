@import "../base.scss";

.container {
  @include containerLandingPage;
}

.content {
  width: 100%;
  max-width: 650px;
  padding: 3rem 0.6rem;
  .form-check {
    margin: 0.7rem 0;
  }
}
.label-mini {
  font-size: 0.8em;
  span {
    cursor: pointer;
  }
}

.jumbotron-how-to-shop {
  height: 480px;
  background-image: url("../../assets/how-to-shop/Group\ 738.png"),
    url("../../assets/how-to-shop/Mask\ Group.png");
  background-size: "", contain;
  background-position: left, 100px 80px;
  background-repeat: no-repeat;
  margin-top: -50px;

  .text-jumbotron {
    padding: 20px;
    padding-top: 170px;
    color: white;
    margin-left: 20px;

    .title {
      font-weight: bold;
      font-size: 32px;
    }

    .desc {
      font-size: 22px;
      font-weight: normal;
      width: 300px;
    }
  }
}

.how-to-shop-content {
  .box-step {
    height: 300px;
    .image-box {
      height: 200px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .step-content {
      ul {
        list-style: none;

        li {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  .info-box {
    margin: 30px 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    overflow-x: hidden !important;
  }

  .jumbotron-how-to-shop {
    display: none;
    margin-top: 5px;
    background-image: url("../../assets/how-to-shop/bg-mobile.svg");
    background-size: contain;
    height: 250px;
    background-position: bottom;
    border-bottom: 56px solid #ef3f3a;

    .text-jumbotron {
      margin-left: 0 !important;

      .title {
        font-size: 24px !important;
      }

      .desc {
        font-size: 14px !important;
      }
    }
  }

  .title-mobile {
    margin-bottom: -3px !important;
  }

  .info-box {
    margin: 10px 0 !important;
  }
}
