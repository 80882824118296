@import "../base.scss";
.MuiBreadcrumbs-ol {
  margin: 1rem 0 !important;
  background-color: white !important;
  padding-left: 80px !important;
  &-item {
    height: 19px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #000000 !important;
  }
  .bold {
    font-weight: 800 !important;
  }
}

@include media("<=phone") {
  .show-m {
    display: block !important;
    .MuiBreadcrumbs-ol {
      display: flex !important;
    }
  }
  // .MuiBreadcrumbs-ol {
  //   display: none !important;
  // }
}
