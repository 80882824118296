@import "../base.scss";

@media only screen and (max-width: 767px) {
  .modal.fade.show {
    // background-color: #fff;
  }

  .modal-content {
    border-color: #fff !important;
    padding: 0;
  }
  // .modal_header {
  //   display: none;
  // }

  .btn_close {
    display: block !important;
    margin-right: 0.5rem;
  }

  .btn_simpan_modal {
    padding: 0.8rem 1rem;
  }
}
