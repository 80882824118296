@import "../base.scss";

.dropdown-profile {
  top: 3rem;
  // right: 1.5rem;
  position: absolute;
  box-shadow: 0px 0px 8px 0px rgba(207, 207, 207, 0.7);
  background-color: #fcfcfc;
  z-index: 9;
  right: 20px;
  &.mobile {
    left: -105px;
    right: 0;
  }
}

.menu-item-profile {
  min-width: 150px;
  height: 50px;

  background-color: #fcfcfc;
  display: flex;
  align-items: left;
  border-radius: 1px solid black;
  transition: 0.5;
  padding: 0.5rem;
  overflow: hidden;
  &:hover {
    color: black;
    text-decoration: none;
    background-color: rgb(245, 244, 244);
  }

  text-decoration: none;
  color: black;
  font-weight: normal;
  font-size: 16px;
}
.icon-button-profile {
  margin: 0 10px;
}
