.footer {
  &--copyright {
    padding: 1rem;
  }

  .footer-p {
    font-size: 14px;
  }
}

.footer-none {
  display: none;
}

.our-partner {
  text-align: center;
  margin-bottom: 1rem;

  h3 {
    margin-bottom: 1rem;
  }
}

.footer-hide {
  display: none;
}
