$redColor: #ed4140;
$grayColor: #f5f5f5;
$greyColor: #757575;

.container_order_list {
  padding: 0 8%;
}

.load_more_order_list {
  padding: 0.5rem 2rem;
  background-color: #7a8d3f;
  color: #fff;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
}

.container_tab {
  display: flex;
  padding: 1rem 0;
  overflow: auto;
}

.btn_tab_list {
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border: 1px solid #9e9e9e;
  margin-right: 10px;
  width: fit-content;
  label {
    white-space: nowrap;
  }
}

.select_tab {
  background-color: #7a8d3f;
  border-color: #7a8d3f;
}

.deselect_tab {
  background-color: #fff;
  border-color: "#9E9E9E";
}

.card_order_list {
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 1rem 0;

  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  label {
    cursor: pointer;
    text-overflow: clip;
    word-wrap: break-word;
    overflow: auto;
  }
}

.gambar_product_list {
  width: 100%;
  // max-height:80px;
  object-fit: cover;
  object-position: center;

  border-radius: 10px;
}

.invoice_status_list {
  display: flex;
  padding: 0 0 0.5rem;
  border-bottom: 2px solid $grayColor;
}
.market_date_list {
  display: flex;
  padding: 0.5rem 0;
}
.foto_total_list {
  display: flex;
}

.label_align_right_list {
  text-align: right;
}
.label_invoice_list {
  color: $greyColor;
  font-weight: 700;
}
.label_status_list {
  font-weight: bold;
}
.label_market_list {
  font-weight: bold;
}
.label_date_list,
.label_total_list {
  color: $greyColor;
  font-weight: 400;
  white-space: nowrap;
}
.label_total_price_list {
  font-weight: bold;
  width: 100%;
  white-space: nowrap;
}
.content_foto_list {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .btn_tab_list {
    padding: 0.3rem 1rem;
    label {
      font-size: 0.8em;
    }
  }
  .card_order_list {
    padding: 0.5rem 0 !important;
    label {
      font-size: 0.8em;
    }
  }
  .label_align_right_list {
    padding-left: 0 !important;
  }
  .content_foto_list {
    padding-right: 2px !important;
  }
}
