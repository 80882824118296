@import "../base.scss";
.container-verifikasi {
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: center;
}

.content-verifikasi {
  width: 100%;
  max-width: 350px;
  // padding: 1rem 0;
  // margin-top: 1rem;
  margin: auto;

  label#success {
    font-weight: bold;
    font-size: 1rem;
  }
}

.menu-area-activation {
  @include widthHeight(100%, 70px);
  padding: 0 5%;
  z-index: 3;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  background-color: #fff;
  top: 0;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.15);
  overflow: visible;

  .col {
    padding: 0;
  }
  .btn-search-nav {
    display: none;
  }
}
.footer-p {
  margin-bottom: 0 !important;
}

.title-verification {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
}
.title-small {
  margin: 10px 0px;
  span {
    font-weight: bold;
  }
}
.button-belanja-sekarang {
  border: none;
  padding: 8px 30px;
  border-radius: 20px;
  color: #fff;
  background-color: #7a8d3f;
  margin: 10px 0px;
}
.button-register-sekarang {
  color: #ed4140;
  margin: 10px 0px;
  padding: 8px 30px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #ed4140;
}

.icon {
  color: #66bb6a;
  margin: 10px;
}
.icon-fail {
  color: red;
  margin: 10px;
}

.br-mobile {
  display: none;
}

.email-resent {
  text-align: center;
  margin-top: 2rem;
  width: 100%;
  max-width: 400px;
}

.succes-aktivasi,
.failed-aktivasi {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  padding: 20px;
  // background-color: #8BC451;
  border-radius: 20px;
}

.loading-set {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;

  padding: 0.5rem 0.5rem;
}

.btn-danger {
  height: auto;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  padding: 0 1rem;
}

.input-grp {
  display: flex;
}

.form-control {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@media only screen and (max-width: 767px) {
  .menu-area-activation {
    justify-content: center;
  }
  .br-mobile {
    display: block;
  }
  .succes-aktivasi,
  .failed-aktivasi {
    box-shadow: none;
  }
}
