@import "../base.scss";

.search {
  @include centerFlexAlign;
  flex-direction: row;
  height: 64px;
  width: 100%;
  margin: 1.5rem 0;
  flex-wrap: wrap;
  padding: 0 20px;

  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  .button-search {
    width: 100px !important;
  }
  &:hover {
    background-color: inherit;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    border: none;
  }
}
input.search-input.form-control.rct-input-main.rbt-input {
  margin: 0;
  width: 100%;
  border: none;
  outline: none;
  background-color: #f5f;
}
//Search in navbar phone
// @include media("<=phone") {
.search-container {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 10px;
}
.container {
  padding: 0 !important;
  button.btn {
    padding: 0;
  }
}

.search-btn {
  float: right;
  @include centerFlex;
  transition: 0.4s;
  cursor: pointer;
  position: relative;
}

.search-txt {
  border: none;
  outline: none;
  background: inherit;
  float: left;
  padding: 0;
  transition: 0.4s;
  width: 0px;
  font-size: 1em;
}
.expand-cart-style {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.expand-content-nav {
  width: 100% !important;
}

.expand {
  // background-color: #fff;
  width: 100% !important;
  padding: 0 1px !important;
}
.search-container:hover > .search-btn {
  background: white;
  color: black;
}
// }

.mobile-view {
  display: none !important;
}

// #typehead.rbt-menu.dropdown-menu.show {
//   z-index: 1;
//   width: 135% !important;
//   margin-left: -8%;
//   margin-top: 5%;
// }

.img-search {
  margin-right: 0.6rem;
  height: 32px;
  width: 32px;
}

.search input {
  text-overflow: ellipsis !important;
}

@media only screen and (max-width: 767px) {
  .rbt-input-main.form-control.rbt-input {
    font-size: 0.8em;
  }
  .img-search {
    margin-right: 0.4rem;
  }
  .mobile-view {
    display: flex !important;
    width: 100%;
    margin: 0;
    margin-bottom: -20px !important;
    height: 40px;

    .search-input {
      .rbt {
        margin-left: -10px !important;
      }
    }
  }
  a.dropdown-item {
    padding: 0.2rem;
  }
  #typehead.rbt-menu.dropdown-menu.show {
    z-index: 1;
    width: 120% !important;
    min-width: 100%;
    // max-width: min-content;
    // margin-left: -8.5%;
  }
  div #typehead-searchnav.rbt-menu.dropdown-menu.show {
    z-index: 1;
    width: 110% !important;
    min-width: 100%;
    // max-width: min-content;
    // margin-left: -8.5%;
  }
}

@media only screen and (max-width: 320px) {
  #typehead.rbt-menu.dropdown-menu.show {
    overflow: hidden;
    width: 138%;
    // max-width: min-content;
    min-width: 100%;
    margin-left: -11%;
  }
}
