@import "../base.scss";

//NEW PROFILE PAGE

.profile-container {
  width: 100%;
  padding: 0 10%;
}

.container-profile-mobile {
  display: none;
}

.profile-title {
  font-weight: bold;
  font-size: 1.4em;
}

.profile-image {
  width: 100%;
  max-height: 100%;
  border-radius: 40px;
}

.container-profile-activity {
  padding: 2.5em;
  border-top: 2px solid hsl(0, 0%, 96%);
  border-bottom: 2px solid #f5f5f5;
  margin: 1rem 0;
  overflow: auto;
}

.profile-detail,
.shopping-activity-container {
  width: 48%;
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  padding: 1rem;
}
.wrapper_input_edit_profile {
  position: relative;
  input,
  textarea {
    border-radius: 3px !important;
  }
}
.counter_char {
  position: absolute;
  right: 20px;
  bottom: 0;
  font-size: 10px;
  &_alamat {
    position: absolute;
    right: 28px;
    bottom: 0;
    font-size: 10px;
  }
}

.right {
  @include right;
}

.left {
  @include left;
}

.card-shop-activity {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    .text-shop-activity {
      color: #e40101;
      cursor: pointer;
      &:hover {
      }
    }
  }
}

.detail-data {
  display: flex;
  flex-direction: column;
}

.img-profile-new {
  #img-profile {
    margin: auto;
  }
}

.btn-change-password {
  @include buttonNewPasar($grayColor, $grayColor, "left");
  font-size: 1em;
}

.btn-change-profile {
  @include buttonNewPasar(#7a8d3f, #7a8d3f, "right");
  color: #fff;
  font-size: 1em;
}

.shopping-activity {
  padding: 2rem 0;
}

.text-shop-activity {
  font-weight: bold;
  text-align: center;
  color: $redColor;
  font-weight: 0.8em;
}

.text-detail-profile {
  font-size: 1em;
  color: #757575;
  text-overflow: clip;
  word-wrap: break-word;
  overflow: auto;

  &-name {
    font-weight: bold;
    font-size: 1.8em;
    -ms-word-break: break-all;
    word-break: break-all;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  &-alamat_profile {
    color: #757575;
    white-space: pre-wrap;
    max-height: 4.5em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.profile-address {
  overflow: auto;
  padding: 1rem 0;
}

.btn-add-address-profile,
.btn-list-address-profile {
  font-size: small;
  float: right;
  background-color: transparent;
  border: none;
  margin: 0 0.5rem;
  font-weight: 700;

  &:hover {
    font-weight: bold;
    color: $redColor;

    img {
      color: $redColor;
    }
  }

  img {
    margin-right: 0.2rem;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.list-address-profile {
  -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.text-on-button {
  margin-left: 0.2rem;
  cursor: pointer;
}

.text_btn_mobile {
  margin-left: 0.2rem;
}

.container_alamat_profile_mobile {
  border: 1px solid $redColor;
  padding: 0.5rem !important;
  border-radius: 20px;

  .text_label {
    margin: 0 !important;
    // border-bottom: 1px solid #eeeeee;
    width: 100%;
  }
}

.icon-chevron {
  margin-left: 8px;
}

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

.list-address-profile-up {
  -webkit-animation: scale-down-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-down-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(0.3);
    transform: scaleY(0.3);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes scale-down-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(0.3);
    transform: scaleY(0.3);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

$custom-file-text: (
  en: "Browse",
  id: "Pilih",
);

///UBAH PROFILE\\\
.modal {
  .modal-dialog.modal_edit_profile {
    .modal-content {
      padding: 2rem !important;
    }
  }
}

.this-edit-profile {
  .col-custom-file {
    display: flex;
    flex-direction: row;
  }

  #customFile.custom-file-input {
    display: none;
  }

  .img-container-upload {
    flex: 1;
  }

  .file-input {
    background-color: #e9e9e9;
    height: 90%;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
  }

  .append-file {
    background-color: $redColor;
    border-radius: 48px 0px 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 8px 8px 15px;

    .cam-icon {
      margin: auto;
      color: #fff;
    }
  }

  .div-direction {
    flex: 2;
    overflow: hidden;
    width: 100%;
    padding-left: 0.3rem;
  }

  .file-upload-name {
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    direction: rtl;
    text-align: left;
    color: #757575;
  }

  .file-direction {
    color: #757575;
    font-size: 0.7em;
    width: 100%;
    text-align: justify;
  }

  .text-divide-edit {
    color: $redColor;
    font-weight: bold;
  }

  .col-form-label,
  .form-check-label {
    font-size: 0.9em;
    color: #757575;
  }

  .jk-content {
    display: flex;
    flex-direction: row;

    .form-check {
      margin-right: 2rem;
    }
  }

  input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: white;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #757575;
  }

  input[type="radio"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #66bb6a;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #757575;
  }

  .form-control {
    border-radius: 10px !important;
    color: #757575 !important;
    font-size: 0.9em !important;

    &:focus,
    &:active {
      box-shadow: none;
      border: 2px solid rgb(160, 160, 160);
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    //   opacity: 0;
    color: $redColor;
  }
}

@media only screen and (max-width: 1164px) {
  .text-on-button {
    font-size: 0.6em;
  }
}

@media only screen and (max-width: 916px) {
  .detail-data,
  .img-profile-new {
    padding: 0 0.7rem;
  }

  .text-detail-profile,
  .text-detail-profile-alamat_profile {
    font-size: 0.8em;

    &-name {
      font-size: 1.1em !important;
    }
  }

  .text-on-button {
    // display: none;
  }

  .shopping-activity {
    padding: 2rem 0 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .profile-title {
    display: none;
  }

  .profile-container {
    padding: 1.3rem;
  }

  .container-profile-activity,
  .profile-address {
    display: none;
  }

  .container-profile-mobile {
    display: block;
  }

  .content-profile-name {
    .right,
    .left {
      margin: 0 !important;
    }
  }

  .content-profile-customer {
    margin-bottom: 1rem;

    &::after {
      padding: 1rem 0;
      border-bottom: 1px solid #eeeeee;
      content: "";
      /* This is necessary for the pseudo element to work. */
      display: block;
      /* This will put the pseudo element on its own line. */
      margin: 0 auto;
      /* This will center the border. */
      width: 90%;
      /* Change this to whatever width you want. */
      padding-top: 20px;
      /* This creates some space between the element and the border. */
    }
  }

  .img-profile-new {
    padding: 0 0.7rem;
  }

  .text-detail-profile-name,
  .text-detail-profile {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .detail-data {
    // overflow: hidden;
    width: 100%;
    font-size: medium;
  }

  .icon-action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .text-aktivitas-belanja {
    font-weight: bold;
    font-size: 1em;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eeeeee;
    width: 100% !important;
  }

  .text-lihat-semua {
    text-align: end;
    width: 100%;
    font-weight: bold;
    margin-top: 0.5rem;
  }

  .content-activities-mobile {
    padding: 1rem 0;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
  }

  .akun {
    padding-top: 1rem;
  }
}
