//Importing bootstrap sass
@import "node_modules/bootstrap/scss/bootstrap";

//COLOR
$greyColor: #8f8f8f;
$lightGrey: #dbdbdb;
$extraLightGreyColor: #ececec;
$whiteColor: #fff;

$headerColor: #ef3f3a;
$searchInNavbar: #f8f8f8;
$redColor: #ed4140;
$grayColor: #f5f5f5;

//FONT

//FUNCTION
@mixin shadowMask($width, $height, $opacity) {
  content: "";
  position: absolute;
  //   background-color: black;
  width: $width;
  height: $height;
  opacity: $opacity;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

@font-face {
  font-family: "Avenir Next";
  font-weight: 400;
  src: url("../assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Bold";
  font-weight: 700;
  src: url("../assets/fonts/AvenirNextLTPro-Bold.otf") format("opentype");
  font-display: swap;
}

$montserrat: "Roboto", sans-serif;

@mixin buttonNewPasar($bg, $borderColor, $float) {
  width: 48%;
  background-color: $bg;
  border-radius: 30px;
  border: 2px solid $borderColor;
  border-color: $bg;
  padding: 0.6rem 0;
  float: $float;
  font-weight: 700;
  font-size: medium;

  &:hover {
    opacity: 1.9;
  }
}

@mixin textOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin activeFocusInput {
  &:active,
  &:focus {
    box-shadow: none;
    border: 2px solid rgb(160, 160, 160);
    outline: none;
  }
}

@mixin buttonDisable {
  opacity: 0.7;
}

@mixin right() {
  float: right;
}

@mixin left() {
  float: left;
}

@mixin centerFlex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin centerFlexAlign {
  display: flex;
  align-items: center;
}

@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
}

@mixin spaceAround {
  display: flex;
  justify-content: space-around;
}

@mixin flexDirectionRow {
  display: flex;
  flex-direction: row;
}

@mixin flexDirectionCol {
  display: flex;
  flex-direction: column;
}

@mixin containerLandingPage {
  width: 100%;
  padding: 0px 5%;
}

@mixin containerPage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 81px;
  margin-bottom: 80px;
}

@mixin buttonHover {
  filter: opacity(0.8);
}

@mixin widthHeight($width, $height) {
  width: $width;
  height: $height;
}

@mixin displayNone {
  display: none;
}

@mixin buttonCounter {
  background-color: transparent;
  border-radius: 50%;
  outline: none;
  border: none;

  &:active {
    position: relative;
    top: 1px;
    border: none;
  }

  &:focus {
    outline: none;
  }
}

@mixin buttonRedLight {
  width: 95%;
  height: 64px;
  border-radius: 5px;
  border: 0;
  font-size: 0.8em;

  //   box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  &:hover {
    box-shadow: 0px 0px 4px 0px rgba(236, 236, 236, 0.5);
  }
}

///media queries from eduardo boucas

$breakpoints: (
  "phone": 767px,
  "phone_small": 428px,
  "tablet": 988px,
  "desktop": 1024px,
) !default;

$media-expressions: (
  "screen": "screen",
  "print": "print",
  "handheld": "handheld",
  "landscape": "(orientation: landscape)",
  "portrait": "(orientation: portrait)",
  "retina2x":
    "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)",
  "retina3x":
    "(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi), (min-resolution: 3dppx)",
) !default;

$unit-intervals: (
  "px": 1,
  "em": 0.01,
  "rem": 0.1,
  "": 0,
) !default;

$im-media-support: true !default;

$im-no-media-breakpoint: "desktop" !default;

$im-no-media-expressions: ("screen", "portrait", "landscape") !default;

@function slice($list, $start: 1, $end: length($list)) {
  @if length($list) < 1 or $start>$end {
    @return ();
  }

  $result: ();

  @for $i from $start through $end {
    $result: append($result, nth($list, $i));
  }

  @return $result;
}

@mixin media($conditions...) {
  @if ($im-media-support and length($conditions) ==0) or
    (not $im-media-support and im-intercepts-static-breakpoint($conditions...))
  {
    @content;
  } @else if ($im-media-support and length($conditions) > 0) {
    @media #{unquote(parse-expression(nth($conditions, 1)))} {
      // Recursive call
      @include media(slice($conditions, 2)...) {
        @content;
      }
    }
  }
}

@function im-log($message) {
  @if feature-exists("at-error") {
    @error $message;
  } @else {
    @warn $message;
    $_: noop();
  }

  @return $message;
}

@mixin log($message) {
  @if im-log($message) {
  }
}

@function noop() {
}

@function im-intercepts-static-breakpoint($conditions...) {
  $no-media-breakpoint-value: map-get($breakpoints, $im-no-media-breakpoint);

  @if not $no-media-breakpoint-value {
    @if im-log("`#{$im-no-media-breakpoint}` is not a valid breakpoint.") {
    }
  }

  @each $condition in $conditions {
    @if not map-has-key($media-expressions, $condition) {
      $operator: get-expression-operator($condition);
      $prefix: get-expression-prefix($operator);
      $value: get-expression-value($condition, $operator);

      @if ($prefix== "max" and $value <=$no-media-breakpoint-value) or
        ($prefix== "min" and $value > $no-media-breakpoint-value)
      {
        @return false;
      }
    } @else if not index($im-no-media-expressions, $condition) {
      @return false;
    }
  }

  @return true;
}

@function get-expression-operator($expression) {
  @each $operator in (">=", ">", "<=", "<", "≥", "≤") {
    @if str-index($expression, $operator) {
      @return $operator;
    }
  }

  // It is not possible to include a mixin inside a function, so we have to
  // rely on the `im-log(..)` function rather than the `log(..)` mixin. Because
  // functions cannot be called anywhere in Sass, we need to hack the call in
  // a dummy variable, such as `$_`. If anybody ever raise a scoping issue with
  // Sass 3.3, change this line in `@if im-log(..) {}` instead.
  $_: im-log("No operator found in `#{$expression}`.");
}

@function get-expression-dimension($expression, $operator) {
  $operator-index: str-index($expression, $operator);
  $parsed-dimension: str-slice($expression, 0, $operator-index - 1);
  $dimension: "width";

  @if str-length($parsed-dimension) >0 {
    $dimension: $parsed-dimension;
  }

  @return $dimension;
}

@function get-expression-prefix($operator) {
  @return if(index(("<", "<=", "≤"), $operator), "max", "min");
}

@function get-expression-value($expression, $operator) {
  $operator-index: str-index($expression, $operator);
  $value: str-slice($expression, $operator-index + str-length($operator));

  @if map-has-key($breakpoints, $value) {
    $value: map-get($breakpoints, $value);
  } @else {
    $value: to-number($value);
  }

  $interval: map-get($unit-intervals, unit($value));

  @if not $interval {
    // It is not possible to include a mixin inside a function, so we have to
    // rely on the `im-log(..)` function rather than the `log(..)` mixin. Because
    // functions cannot be called anywhere in Sass, we need to hack the call in
    // a dummy variable, such as `$_`. If anybody ever raise a scoping issue with
    // Sass 3.3, change this line in `@if im-log(..) {}` instead.
    $_: im-log("Unknown unit `#{unit($value)}`.");
  }

  @if $operator== ">" {
    $value: $value + $interval;
  } @else if $operator== "<" {
    $value: $value - $interval;
  }

  @return $value;
}

@function parse-expression($expression) {
  // If it is part of $media-expressions, it has no operator
  // then there is no need to go any further, just return the value
  @if map-has-key($media-expressions, $expression) {
    @return map-get($media-expressions, $expression);
  }

  $operator: get-expression-operator($expression);
  $dimension: get-expression-dimension($expression, $operator);
  $prefix: get-expression-prefix($operator);
  $value: get-expression-value($expression, $operator);

  @return "(#{$prefix}-#{$dimension}: #{$value})";
}

@function to-number($value) {
  @if type-of($value) == "number" {
    @return $value;
  } @else if type-of($value) != "string" {
    $_: im-log("Value for `to-number` should be a number or a string.");
  }

  $first-character: str-slice($value, 1, 1);
  $result: 0;
  $digits: 0;
  $minus: ($first-character== "-");
  $numbers: (
    "0": 0,
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
  );

  // Remove +/- sign if present at first character
  @if ($first-character== "+" or $first-character== "-") {
    $value: str-slice($value, 2);
  }

  @for $i from 1 through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if not(index(map-keys($numbers), $character) or $character== ".") {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i));
    }

    @if $character== "." {
      $digits: 1;
    } @else if $digits==0 {
      $result: $result * 10 + map-get($numbers, $character);
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }

  @return if($minus, -$result, $result);
}

@function to-length($value, $unit) {
  $units: (
    "px": 1px,
    "cm": 1cm,
    "mm": 1mm,
    "%": 1%,
    "ch": 1ch,
    "pc": 1pc,
    "in": 1in,
    "em": 1em,
    "rem": 1rem,
    "pt": 1pt,
    "ex": 1ex,
    "vw": 1vw,
    "vh": 1vh,
    "vmin": 1vmin,
    "vmax": 1vmax,
  );

  @if not index(map-keys($units), $unit) {
    $_: im-log("Invalid unit `#{$unit}`.");
  }

  @return $value * map-get($units, $unit);
}

@mixin media-context($tweakpoints: (), $tweak-media-expressions: ()) {
  // Save global configuration
  $global-breakpoints: $breakpoints;
  $global-media-expressions: $media-expressions;

  // Update global configuration
  $breakpoints: map-merge($breakpoints, $tweakpoints) !global;
  $media-expressions: map-merge(
    $media-expressions,
    $tweak-media-expressions
  ) !global;

  @content;

  // Restore global configuration
  $breakpoints: $global-breakpoints !global;
  $media-expressions: $global-media-expressions !global;
}

nav {
  @include containerLandingPage;

  .MuiBreadcrumbs-ol,
  ol {
    padding: 0 !important;
    margin: 30px 0 10px 0 !important;
    color: black;

    li,
    p,
    a {
      font-size: 14px !important;
      font-weight: 300 !important;
      cursor: pointer;
      text-transform: capitalize;
    }
  }
}

// helpers
.cursor-pointer {
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.color-primary {
  color: $redColor;
}

.desktop-hide {
  display: none;
}

.input-select {
  padding: 0.5rem 5rem;
  color: #757575;
  font-size: 0.9em;
  border: 1px solid #9e9e9e;
  box-sizing: border-box;
  border-radius: 10px;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // -o-appearance: none;

  // &::-ms-expand {
  //   display: none;
  // }
}

.btn-chat-admin {
  position: fixed;
  background: #ed4140;
  bottom: 3rem;
  right: 3rem;
  color: white !important;
  font-weight: bold;
  border-radius: 40px;
  padding: 1rem 1.2rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  z-index: 9999;
}

@include media("<=phone") {
  .sm-hide {
    display: none;
  }

  .desktop-hide {
    display: block;
  }

  .btn-chat-admin {
    padding: 0.7rem 0.9rem;
    font-size: 14px;
    right: 1.5rem;
    bottom: 4.5rem;
  }

  .btn-chat-admin img {
    width: 25px;
  }
}

html {
  scroll-behavior: smooth !important;
}
