@import "../base.scss";

.container-search-kota {
  max-height: inherit;
  height: inherit;
}
.search-input-kota {
  width: 100%;
  padding: 5px 6px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.container-kota {
  //   height: 200px;
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 5px;
}

.content-kota {
  padding: 6px;
  &:hover {
    cursor: pointer;
    background-color: rgba(241, 241, 241, 0.973);
  }
}

// ::-webkit-scrollbar {
//   width: 0.8rem;
//   height: inherit;
//   background-color: rgb(255, 255, 255);
//   border-radius: 50px;
// }

// ::-webkit-scrollbar-track {
//   border-radius: 50px;
//   border: 1px solid rgb(255, 255, 255);
//   box-shadow: inset 0 0 2px 2px rgba(211, 211, 211, 0.25);
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 50px;
//   background-color: rgba(231, 231, 231, 0.651);
// }

@include media("<=phone") {
  .container-input {
    padding: 0 0.5rem;
  }
  .content-kota {
    padding-left: 14px;
  }
}
