@import "../base.scss";

//modal comp
.modal-container {
  z-index: 1;
  background-color: #f7f;
}
.modal-content {
  padding: 0px;
}

.text-confirm {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}

.input-group-text {
  background-color: rgb(189, 189, 189) !important;
  font-size: 0.75em;
  padding: 5px;
}
.text-description {
  margin-top: 16px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #000000;
}

.button-confirm-modal {
  @include spaceAround;
  margin-top: 1.3rem;
}
button.button-modal{
        padding: 5px 20px !important;
        border-radius: 5px;
}

.input-group {
  height: auto;

  .amount-purchase {
    @include spaceBetween;
    align-items: center;
    width: 20%;
    margin-left: 10px;
  }
}


.modal__set {
  padding: 20px;
  max-width: 676px;
  width: 100%;
  height: 200px;
  background: transparent !important;
  border-radius: 10px;
}

.button_modal_fail_checkout{
  padding: 0.4rem 1rem;
  border-radius: 10px;
  border: none;
  &:active, &:focus{
    outline:none;
  }
}

.modal_fail_checkout_cart{
   padding: 20px;
  max-width: 676px;
  width: 100% !important;
  height: 200px;
  border-radius: 10px;
}


.ReactModal__Overlay {
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  transition: opacity 500ms ease-in-out;
  @include centerFlex;
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  transition: opacity 500ms ease-out;
  opacity: 0;
}