@import "../base.scss";

//modal comp
.modal-container {
  z-index: 9;
  background-color: #fff !important;
}
.modal-content {
  padding: 0px !important;
}

input.form-control,
textarea.form-control {
  color: #000000;
}

.text-confirm {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}

.glass {
  height: 30px !important;
}

.input-group-text {
  background-color: rgb(189, 189, 189) !important;
  font-size: 0.75em;
  padding: 5px;
}
.text-description {
  margin-top: 16px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #000000;
}

.button-confirm {
  @include spaceAround;
  margin-top: 24px;
  .button__ {
    width: 200px;
    @include buttonRedLight;
    &:hover {
      opacity: 0.9;
    }
  }
  a {
    width: 40%;
  }
}

.input-group {
  height: auto;

  .amount-purchase {
    @include spaceBetween;
    align-items: center;
    width: 20%;
    margin-left: 10px;
  }
}

.button-done {
  @include widthHeight(180px, 48px);

  background: #ef3f3a;
  border-radius: 5px;
  &:hover {
    @include buttonHover;
  }
}

.button-rePurchase {
  @include widthHeight(180px, 48px);

  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 5px;
  &:hover {
    @include buttonHover;
  }
}

.modal__set {
  @include widthHeight(632px, auto);
  padding: 32px;
  background-color: #fff;
  border-radius: 10px;
}

.modal__konf {
  @include widthHeight(632px, auto);
  padding: 32px;
  background-color: #fff;
  border-radius: 10px;
}

.ReactModal__Overlay {
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  transition: opacity 500ms ease-in-out;
  @include centerFlex;
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  transition: opacity 500ms ease-out;
  opacity: 0;
}

.button-custom-pesanan {
  display: flex;
  justify-content: center;
}

.thumb-img-container {
  height: 150px;
  text-align: center;
}
.thumb-img {
  max-width: 100%;
  max-height: 100%;
}

.button-selesai {
  @include spaceAround;
  margin-top: 24px;
  button.button_selesai {
    width: 100% !important;
    @include buttonRedLight;
    height: 40px;
    &:hover {
      opacity: 0.9;
    }
  }
  a {
    width: 40%;
  }
}

/////====Modal New Form Pembelian====\\\\\
.modal_header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modal_header-alamat {
  width: 100%;
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
}

.btn_close {
  background-color: transparent;
  border: none;
}

.text_label {
  color: $redColor;
  font-weight: 700;
  font-size: 13px;
}

.form-control.input_modal,
.form-control.input-search-map {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  padding: 0;
  box-shadow: none;

  &::placeholder {
    font-style: italic;
    color: #e0e0e0;
    font-weight: 400;
    font-size: 14px;
  }
  &:focus {
    box-shadow: none;
    border-bottom: 2px solid #9e9e9e;
  }
}

.map_ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.map_pin {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
}

.text_pin {
  margin-left: 1rem;
  font-style: italic;
  color: #e0e0e0;
  font-size: 14px;
}

.btn_simpan_modal,
.btn_save_go_modal {
  width: 48%;
  border-radius: 30px;
  border: none;
  color: #fff;
  padding: 0.6rem 3rem;
  background: #7a8d3f;
  border: 1px solid #7a8d3f;
  margin: auto;
  &:active,
  &:focus {
    outline: none;
  }
}

.simpan-form {
  width: 70%;
  border-radius: 30px;
  border: none;
  color: #fff;
  padding: 0.6rem 3rem;
  background: $redColor;
  margin: auto;
  &:active,
  &:focus {
    outline: none;
  }
}

.btn_disable {
  @include buttonDisable;
}
.btn_save_go_modal {
  width: auto !important;
}

.btn_add_modal {
  width: 48%;
  background: #fff;
  border-radius: 30px;
  border: 1px solid #7a8d3f;
  color: #7a8d3f;
  padding: 0.6rem 0;
  float: left;
  &:active,
  &:focus {
    outline: none;
  }
}

///Card Alamat
.card-alamat {
  border: 2px solid $grayColor;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
  &:hover {
    border: 2px solid $redColor;
  }
}

.label-address-name {
  color: $redColor;
  font-weight: 800;
  font-size: 0.8em;
  float: left;
}

.alamat_utama {
  font-style: italic;
  color: #c9c3c3;
  font-size: 0.8em;
  font-weight: 800;
  padding-left: 0.5em;
}

.btn_delete_address {
  background-color: transparent;
  border: none;
  float: right;
}

.content-name {
  border-bottom: 2px solid $grayColor;
  padding: 0.4em 0;
}

.label-content-name {
  font-size: 0.9em;
  font-weight: bold;
  &.right {
    float: right;
  }
  &.left {
    float: left;
  }
}

.content-address {
  display: flex;
  flex-direction: row;
  padding: 0.4em 0;
}

.map_pin-address {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  float: left;
  height: fit-content;
}
.text_pin-address {
  margin-left: 5px;
  float: right;
  font-size: 0.9em;
}

.card_address_popup {
  // display: block;
  padding: 0.7rem;
  border: 2px solid $redColor;
  border-radius: 20px;
  margin: 0.5rem 0;
  max-height: 160px;
  overflow: hidden;
}

//CARD ALAMAT at PROFILE
.container-address-profile {
  padding: 0.7rem 0;
  border: 2px solid $redColor;
  border-radius: 20px;
  margin: 0.5rem 0;
  max-height: 120px;
  overflow: hidden;
}

.receiver-profile,
.address-profile,
.pin-profile {
  display: flex;
  flex-direction: column;
  overflow: inherit;
  text-overflow: ellipsis;
  max-height: 100px;
}
.action-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 !important;
}

.text_label_profile {
  font-size: 0.8em;
  color: #757575;
  display: block;
  .data-address-profile_ {
    color: #000;
  }
  // flex-direction: column
}

.data-address-profile {
  @include textOverflow;
  color: #000;
  max-height: 50px;
}
.map_pin-address-profile {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px 15px;
  float: left;
  height: fit-content;
  width: fit-content;
}

.ubah-address-profile {
  color: #757575;
  cursor: pointer;
  label {
    padding: 0 0.2rem;
    cursor: pointer;
  }
  &:hover {
    color: #000;
  }
}
.hapus-address-profile {
  color: #757575;
  cursor: pointer;
  margin-left: 1rem;
  label {
    padding: 0 0.2rem;
    cursor: pointer;
  }
  &:hover {
    color: #000;
  }
}

.modal-map-show {
  height: 60vh;
  max-height: 600px;
  width: 100%;
}

///CONFIRM FIRST ADDRESS
.confirm-set-first-address {
  padding: 1rem;
}

.label-set-first-address {
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.text-set-first-address {
  font-size: 1.1em;
  color: #757575;
  text-align: center;
  width: 100%;
}

.btn-container-set-address {
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

.btn-cancel-set-address-profile {
  @include buttonNewPasar("#e5e5e5", $redColor, "left");
  color: $redColor;
  font-size: 1em;
}
.btn-set-address {
  @include buttonNewPasar(rgb(122, 141, 63), rgb(122, 141, 63), "right");
  color: #fff;
  font-size: 14px;
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  // border-color: $redColor !important;
}
.btn-delete-address {
  @include buttonNewPasar($redColor, $redColor, "right");
  font-size: 14px;
  color: #fff;
  padding: 8px;
}

.card-alamat-profile-mobile {
  display: none;
}

#mapid {
  height: 290px;
}

.container-icon-arrow {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon-arrow-left {
  cursor: pointer;
  margin-bottom: 8px;
}

.label-lokasi {
  width: 100%;
  font-weight: bold;
  text-align: center;
}

.current-location {
  position: absolute;
  z-index: 9999 !important;
  background-color: white;
  padding: 10px;
  // align-items: center;
  border-radius: 100%;
  margin: 25px 10px;
  cursor: pointer;
  bottom: 0;
  right: 0;
  height: 45px;
  width: 45px;
  .current-location-icon {
    fill: black !important;
  }
}

.container-location {
  width: 100%;
  margin-top: 20px;

  .location-name {
    margin-bottom: 0;
    font-weight: bolder;
  }

  .detail-location {
    font-size: 14px;
  }
}

.search-field {
}

// .leaflet-top,
// .leaflet-left {
//   display: flex;
// }

.modal_header-form {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text_location {
  margin-left: 1rem;
  font-size: 15px;
}

.mt-6 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 916px) {
  .label-on-action {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .card-alamat-profile {
    display: none;
  }
  .card-alamat-profile-mobile {
    display: block;
    padding: 0.7rem;
    border: 2px solid $redColor !important;
    border-radius: 20px;
    margin: 0.5rem 0;
    max-height: 160px;
    overflow: hidden;
  }

  .content-profile-name {
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #eeeeee;
    display: flex;
    font-size: 15px;
    justify-content: space-between;
  }
  .text-profile-name {
    width: 50%;
    font-weight: bold !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }

  .label-profile-name {
    font-weight: bold !important;
  }

  .content-profile-address {
    padding-top: 0.5em;
    display: flex;
    justify-content: space-between;
    max-height: 90px;
  }

  .content-address {
    padding: 0 0.5rem;
    width: 100%;
    overflow: hidden;
  }
  .text-address {
    @include textOverflow;
    font-size: 15px;
  }

  .page-list-address-profile {
    padding: 1rem;
  }

  .container-card-address {
    padding: 0.3rem;
    border: 1px solid $redColor;
    border-radius: 10px;
  }
  .not-clicked {
    border: 1px solid $grayColor;
  }
  .button-container-list-address {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
  }
  .btn-list-address {
    width: 48%;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    border: 1px solid $redColor;
    font-size: 0.8em;
    padding: 0.6rem 0;
    // margin: auto;
    &.select {
      background: $redColor;
      color: #fff;
    }
    &.add {
      background: #fff;
      color: $redColor;
    }
  }

  .btn_add_modal,
  .btn_simpan_modal {
    font-size: 13px;
  }

  .mt-6 {
    display: flex;
    justify-content: space-between;
  }

  .added-when-click {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .ubah-address-profile,
    .hapus-address-profile {
      width: fit-content;
      cursor: pointer;
    }
  }
  .label-button-listaddress {
    color: #757575;
  }
}
