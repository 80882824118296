@import "../base.scss";

//With Google
.content-form {
  min-width: 30%;
  padding: 2%;
  box-shadow: 0px 7px 15px 6px rgba(0, 0, 0, 0.36);
}

//popover
.failed-popover {
  background-color: rgba(255, 235, 58, 0.966);
  color: white;
  text-align: center;
}

.success-popover {
  text-align: center;
  background-color: rgba(88, 255, 102, 0.89);
}

.MuiTypography-h5 {
  font-weight: bold !important;
}

.pass-wrapper {
  position: relative;
  display: flex;
}

#input_change_pass {
  border-radius: 5px !important;
}

i {
  position: absolute;
  top: 20%;
  right: 3%;
}
i:hover {
  color: #00fcb6;
  cursor: pointer;
}

i.eye_icon_change_pass {
  position: absolute;
  top: 20%;
  right: 10px;
}

.prefix {
  position: absolute;
  top: 20%;
  left: 2%;
}
.input-telephon-number {
  &.form-control {
    padding: 0.375rem 2.5rem;
  }
}
.only-number {
  &.form-text {
    &.text-muted {
      color: red !important;
    }
  }
}

.text-reset-password {
  font-weight: bold;
  text-align: center;
  margin: 5px 0 20px 0;
}

.container-change-password {
  padding: 2rem 1rem;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}
.content-change-password {
  width: 100%;
  max-width: 430px;
  padding: 0 1rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.img-container {
  text-align: center;
  padding: 0 0 1rem 0;
}

.container-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.button-content {
  width: 48%;
  padding: 0 0.5rem;
  .btn-secondary {
    border-radius: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.pass-info {
  background-color: #fafafa;
  font-size: 0.8em;
  text-align: justify;
  padding: 12px;
  border-radius: 12px;
  color: #757575;
  margin-top: 20px;
}

//nohp
.container_no-hp_profile {
  display: flex;
  flex-direction: row !important;
  position: relative;
  .append_code {
    padding-left: 0.5rem;
    position: absolute;
    z-index: 1;
    margin-top: 1.25rem;
  }
  .input-nomor {
    height: 4rem;
    font-size: 1em;
    background: #fdfdfd;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding-left: 3rem;
  }
}

.col-login {
  input {
    border-radius: 8px !important;
  }
}

.reframe-form {
  width: 100%;
  box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.05);
  padding: 5% 12%;
  label {
    margin-top: 10px;
  }
  .back-ico {
    display: flex;
    position: absolute;
    cursor: pointer;
    margin-top: 5px;
  }
}

.btn-eye {
  background: none;
  border: none;
  position: absolute;
  right: 2%;
  top: 20%;
  &.confirm {
    top: 20%;
  }
}

.button-regist {
  background-color: #7a8d3f !important;
  border: 0 !important;
  border-radius: 60px !important;
  font-weight: bold !important;
  width: 40% !important;
  margin: auto !important;
  padding: 15px !important;
}

.logo-mobile {
  display: none;
}

.login-alert {
  background: #fef5f5 !important;
  color: #ed4140 !important;
}
.regist-pass {
  .form-control.is-invalid,
  .form-control.is-valid {
    background-position: right calc(1.875em + 0.1875rem) center !important;
  }
}

.invalid {
  color: #ed4140 !important;
  font-size: 80% !important;
}

.valid {
  color: #66bb6a !important;
  font-size: 80% !important;
}

.pass-icon {
  font-size: 1rem !important;
  margin-right: 10px;
}

.btn-google {
  background-color: white !important;
  border-radius: 60px !important;
  border-color: #e0e0e0 !important;
  padding: 12px !important;
}

@media only screen and (max-width: 767px) {
  .wrap-ic {
    width: 50% !important;
    button {
      padding: 8% !important;
    }
  }
  .button-regist {
    width: 80% !important;
  }
  .mobile-title {
    display: none;
  }
  .reframe-form {
    width: 100%;
    box-shadow: none !important;
    padding: 10% !important;
    padding-right: 2% !important;
  }
  .logo-mobile {
    display: block;
    .logo-pasar {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  .form-login {
    box-shadow: none !important;
    padding: 5% !important;
    h4 {
      display: none;
    }
  }
  .container-button {
    flex-direction: column;
  }
  .button-content {
    width: 100%;
    padding: 0.3rem 0;
  }
  .illustration-m {
    display: none;
  }
  .logo-pasar-login {
    display: none;
  }
  .regist-mobile {
    padding: 2% 1rem 2% 3rem !important;
  }
}
